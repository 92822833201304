import { Stack, Box, CircularProgress } from "@mui/material";
import { VideoCard } from "./";

const Videos = ({ videos, direction, page, scrollheight, detail }) => {
  return (
    <div style={{ overflowY: 'auto', height: scrollheight, width: page }}>
      {videos && videos.length > 0 && (
        <Stack
          direction={direction || "row"}
          flexWrap={"wrap"}
          justifyContent={"start"}
          gap={2}
          sx={{ justifyContent: { xs: "center" } }}
        >
          {videos.map((item, idx) => (
            <Box key={idx}>{item.MEDIA_ID && <VideoCard video={item} detail={detail}/>}</Box>
          ))}
        </Stack>
      )}
      {!(videos && videos.length > 0) && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh', width: page }}>
          <CircularProgress style={{'color': 'white'}} size={30} thickness={5} />
        </div>
      )}
    </div>
  );
};

export default Videos;
