import { useState, useEffect } from "react";
import { Alert, Box, Typography } from "@mui/material";
import { Videos } from "./";
import { fetchFromAPI } from "../utils/fetchFromAPI";
import { useParams } from "react-router-dom";

const SearchFeed = () => {
  const [connatixBlocked, setConnatixBlocked] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState(true);
  const [videos, setVideos] = useState([]);
  const { searchTerm } = useParams();

  useEffect(() => {
    function handleConnatixStatusChange() {
      if (localStorage.getItem('connatix_status') === '0') {
        setConnatixBlocked(true);
      } else {
        setConnatixBlocked(false);
      }
    }
    handleConnatixStatusChange();

    const timeout = setTimeout(() => {
      handleConnatixStatusChange();
    }, 1500);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchFromAPI(`search?title=${searchTerm}&timestamp=` + Date.now() ).then((data) => {
      setVideos(data.Items);
      setLoading(false);
    });
  }, [searchTerm]);

  return (
    <div style={{ overflow: 'auto', height: '100vh', width: '100vw' }}>
      {loading || videos.length > 0 ? (
        <Box p={2} sx={{ overflowY: "auto", height: "90vh", flex: 2 }}>
          {(connatixBlocked) && (<Alert style={{ marginBottom: "35px" }} severity="error">
            It seems like you have an ad-blocker enabled.
            To use all aspects of our site, please disable your ad-blocker and reload our page.
          </Alert>)}
          <Typography
            variant="h4"
            fontWeight="bold"
            mb={2}
            sx={{ color: "white" }}
            fontFamily={"'Glacial Indifference', sans-serif"}
          >
            Search Results For:{" "}
            <span style={{ color: "#edb818" }}>{searchTerm}</span> Videos
          </Typography>
          {screenWidth <= 1200 && <Videos videos={videos} page={'89.5vw'} />}
          {screenWidth > 1200 && <Videos videos={videos} page={'100vw'} />}
        </Box>
      ) : (
        <Box
          p={2}
          sx={{
            overflowY: "auto",
            height: "94vh",
            flex: 2,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Alert
            style={{
              marginBottom: "35px",
              width: "500px",
              height: "75px",
              alignItems: 'center'
            }}
            severity="warning"
          >
            We didn't find any videos that matched what you
            searched. Please try again.
          </Alert>
        </Box>
      )}
    </div>
  );
};

export default SearchFeed;
