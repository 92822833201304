import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Box } from "@mui/material";

import {
  Navbar,
  VideoDetail,
  Feed,
  SearchFeed,
} from "./components";

const App = () => (
  <div style={{ backgroundColor: '#222337', overflow: 'hidden' }}>
    <BrowserRouter>
      <Box>
        <Navbar
          sx={{
            zIndex: 100,
          }}
        />
        <Routes>
          <Route path="/" exact element={<Feed />} />
          <Route path="/cooking/:id" element={<VideoDetail />} />
          <Route path="/travel/:id" element={<VideoDetail />} />
          <Route path="/finance/:id" element={<VideoDetail />} />
          <Route path="/tech/:id" element={<VideoDetail />} />
          <Route path="/cars/:id" element={<VideoDetail />} />
          <Route path="/search/:searchTerm" element={<SearchFeed />} />
        </Routes>
      </Box>
    </BrowserRouter>
  </div>
);

export default App;
