import { Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { logo } from "../utils/constants";
import SearchBar from "./SearchBar";

const handleClick = () => {
    window.location.pathname = '/';
    document.title = 'MM Watch | Endless Entertainment';
    document.querySelector('meta[property="og:title"]').setAttribute('content', 'MM Watch | Endless Entertainment');
    document.querySelector('meta[name="description"]').setAttribute('content', 'Discover endless entertainment at MM Watch. Watch videos that captivate and engage. Join our community and start exploring MM Watch.');
    document.querySelector('meta[property="og:description"]').setAttribute('content', 'Discover endless entertainment at MM Watch. Watch videos that captivate and engage. Join our community and start exploring MM Watch.');
};

const Navbar = () => (
  <Stack
    direction="row"
    alignItems="center"
    p={2}
    sx={{
      position: "sticky",
      background: "#edb818",
      top: 0,
      justifyContent: "space-between"
    }}
  >
    <Link style={{ display: "flex", alignItems: "center" }} onClick={handleClick}>
      <img src={logo} alt="logo" height={45} />
    </Link>
    <SearchBar />
  </Stack>
);

export default Navbar;
