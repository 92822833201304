import { useState, useEffect } from "react";
import { Typography, Stack } from "@mui/material";
import { categories } from "../utils/constants";

const Sidebar = ({ selectedCategory, setSelectedCategory }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Stack
      direction={"row"}
      sx={{
        overflowY: "auto",
        height: { sx: "auto", md: "95%" },
        flexDirection: { md: "column" },
      }}
    >
      {categories.map((category) => (
        <button
          className="category-btn"
          onClick={() => setSelectedCategory(category.name)}
          style={{
            background: category.name === selectedCategory && "#edb818",
            color: "white",
          }}
          key={category.name}
        >
          <span
            style={{
              color: category.name === selectedCategory ? "white" : "#edb818",
              marginRight: "15px",
            }}
          >
            {category.icon}
          </span>
          <span
            style={{ opacity: category.name === selectedCategory ? "1" : 0.8 }}
          >
            {category.name}
          </span>
        </button>
      ))}
      <a href="./DMCA_Policy.pdf" target="_blank">
        <Typography variant="subtitle1" color="lightgrey" style={{marginTop: screenWidth >= 900 ? 25 : 10, marginRight: screenWidth >= 900 ? 0 : 15, lineHeight: 1.5, textAlign: 'center'}} fontFamily={"'Glacial Indifference', sans-serif"} fontSize={14}>
          {'DMCA Policy'}
        </Typography>
      </a>
      <a href="./Privacy_Policy.pdf" target="_blank">
        <Typography variant="subtitle1" color="lightgrey" style={{marginTop: screenWidth >= 900 ? 15 : 10, marginRight: screenWidth >= 900 ? 0 : 15, lineHeight: 1.5, textAlign: 'center'}} fontFamily={"'Glacial Indifference', sans-serif"} fontSize={14}>
          {'Privacy Policy'}
        </Typography>
      </a>
      <Typography variant="subtitle1" color="lightgrey" style={{marginTop: screenWidth >= 900 ? 25 : 3, marginRight: screenWidth >= 900 ? 0 : 15, lineHeight: 0.95, textAlign: 'center'}} fontFamily={"'Glacial Indifference', sans-serif"} fontSize={10}>
        {'© Mobile Media Labs FZ-LLC'}
      </Typography>
    </Stack>
  )
};

export default Sidebar;
