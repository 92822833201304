import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Paper, IconButton, Typography } from "@mui/material";
import { Search } from "@mui/icons-material";
import { coin } from "../utils/constants";
import { listeners } from "../utils/constants";
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';

const SearchBar = () => {
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const [balance, setBalance] = useState("-1");
  const navigate = useNavigate();
  const [consentValue, setConsentValue] = useState(window.__lxG__consent__);

  useEffect(() => {
    const handleClickioConsent = () => {
      setConsentValue(window.__lxG__consent__);
    };

    document.documentElement.addEventListener('clickioConsentEvent', handleClickioConsent);

    return () => {
      document.documentElement.addEventListener('clickioConsentEvent', handleClickioConsent);
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchTerm) {
      navigate(`/search/${searchTerm}`);
    }
    setSearchTerm("");
  };

  if (!listeners.balanceSwitch) {
    listeners.balanceSwitch = true;
    document.addEventListener("balance", async (e) => {
      if(localStorage.getItem("USER_ID") && localStorage.getItem("USER_ID") !== null) {
        const result = await fetch("https://analytics.mm-watch.com/dragbelow?userId=" + localStorage.getItem("USER_ID") + "&date=" + Date.now());
        setBalance(new Intl.NumberFormat(navigator.language).format(await result.text()));
      }
    });
  }

  if(location.pathname === '/' || (!(localStorage.getItem("USER_ID") && localStorage.getItem("USER_ID") !== null))) {
    return (
      <div style={{ display: 'flex' }}>
        {consentValue && consentValue.getState() !== null && (<Button variant="contained" style={{ marginRight: 10, backgroundColor: '#fac32a00', color: 'white', fontSize: 9, boxShadow: "none", height: 10, top: 15, minWidth: '10px', maxWidth: '10px' }} onClick={() => {
            window.__lxG__consent__.showConsent();
        }}><PrivacyTipIcon /></Button>)}
        <Paper
          component={"form"}
          onSubmit={handleSubmit}
          sx={{
            borderRadius: 20,
            border: "1px solid #e3e3e3",
            pl: 2,
            boxShadow: "none",
            mr: { sm: 5 },
          }}
        >
          <input
            style={{ maxWidth: "40vw" }}
            className="search-bar"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
          <IconButton type="submit" sx={{ p: "10px", color: "#edb818" }}>
            <Search />
          </IconButton>
        </Paper>
      </div>
    );
  } else if(localStorage.getItem("USER_ID") && localStorage.getItem("USER_ID") !== null && balance !== "-1") {
    return (
      <div style={{ display: 'flex' }}>
        {consentValue && consentValue.getState() !== null && (<Button variant="contained" style={{ marginRight: 10, backgroundColor: '#fac32a00', color: 'white', fontSize: 9, boxShadow: "none", height: 10, top: 9, minWidth: '10px', maxWidth: '10px' }} onClick={() => {
          window.__lxG__consent__.showConsent();
        }}><PrivacyTipIcon /></Button>)}
        <div style={{ display: 'flex', textAlign: 'right', alignItems: 'center', backgroundColor: '#FFFFFF', borderRadius: 10, paddingLeft: 10, paddingRight: 10 }}>
          <img src={coin} alt="coin" height={20} />
          <Typography variant="h4" fontWeight="bold" color="#dcc572" fontFamily={"'Glacial Indifference', sans-serif"} fontSize={25} style={{marginLeft: 5}}>
            {balance}
          </Typography>
        </div>
      </div>
    );
  }
};

export default SearchBar;
