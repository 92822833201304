import { useState, useEffect } from "react";
import { Alert, Box, Stack, Typography } from "@mui/material";
import { Sidebar, Videos } from "./";
import { fetchFromAPI } from "../utils/fetchFromAPI";
import { useSearchParams } from "react-router-dom";

const Feed = () => {
  const [connatixBlocked, setConnatixBlocked] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [videos, setVideos] = useState([]);
  
  const [searchParams] = useSearchParams();
  searchParams.get("userId");

  useEffect(() => {
    function handleConnatixStatusChange() {
      if (localStorage.getItem('connatix_status') === '0') {
        setConnatixBlocked(true);
      } else {
        setConnatixBlocked(false);
      }
    }
    handleConnatixStatusChange();

    const timeout = setTimeout(() => {
      handleConnatixStatusChange();
    }, 1500);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if(searchParams.get("u")) {
      if (localStorage.getItem("USER_ID") !== searchParams.get("u")) {
        localStorage.setItem("USER_ID", searchParams.get("u"));
      }
    }
  }, [searchParams]);

  useEffect(() => {
    selectedCategory && selectedCategory !== "Popular"
      ? fetchFromAPI(
          `search?playlist=${selectedCategory}&timestamp=` + Date.now()
        ).then((data) => {
          setVideos(data.Items);
        })
      : fetchFromAPI("home?timestamp=" + Date.now()).then((data) => {
          setVideos(data.Items);
        });
  }, [selectedCategory]);

  return (
    <Stack sx={{ flexDirection: { sx: "column", md: "row" } }}>
      <Box
        sx={{
          height: { sx: "auto", md: "94.5vh" },
          borderRight: "1px solid #3d3d3d",
          px: { sx: 0, md: 2 },
        }}
        style={{backgroundColor: '#1d1d2d'}}
      >
        <Sidebar
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
      </Box>

      <Box p={2} sx={{ overflowY: "auto", height: "90vh", flex: 2 }}>
          {(connatixBlocked) && (<Alert style={{ marginBottom: "35px" }} severity="error">
            It seems like you have an ad-blocker enabled.
            To use all aspects of our site, please disable your ad-blocker and reload our page.
          </Alert>)}
        <Typography
          variant="h4"
          fontWeight="bold"
          mb={2}
          sx={{ color: "white" }}
          fontFamily={"'Glacial Indifference', sans-serif"}
        >
          {!selectedCategory ? "Popular " : selectedCategory + " "}
          {"  "}
          <span style={{ color: "#edb818" }}>Videos</span>
        </Typography>
        <Videos videos={videos} page={'89.5vw'} scrollheight={'100vh'} />
        <div id="player"></div>
      </Box>
    </Stack>
  );
};

export default Feed;
