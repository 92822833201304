import { Link } from "react-router-dom";
import { Typography, Card, CardContent, CardMedia } from "@mui/material";

const VideoCard = ({
  video: { THUMBNAIL_URL, DURATION, MEDIA_ID, TITLE, DESCRIPTION, PLAYLIST }, detail,
}) => {
  const formatDuration = (dur) => {
    return `${("0" + Math.floor(dur / 60)).slice(-2)}:${(
      "0" + Math.floor(dur % 60)
    ).slice(-2)}`;
  };
  const TruncatedText = ({ text, maxLines }) => {
    const style = {
      display: '-webkit-box',
      WebkitLineClamp: maxLines,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    };

    return <div style={style}>{text}</div>;
  };
  return (
    <Link to={'/' + PLAYLIST.toLowerCase() + "/" + MEDIA_ID} reloadDocument={detail}>
      <Card
        sx={{
          width: { xs: "290px", sm: "358px", md: "320px", backgroundColor: "#22303C" },
          boxShadow: "none",
          borderRadius: 0,
        }}
        style={{borderRadius: 10, borderWidth: 0}}
      >
          {THUMBNAIL_URL && (<CardMedia
              alt={TITLE}
              sx={{
                width: {
                  xs: "100%",
                  sm: "358px",
                  md: "320px",
                },
                height: 180,
                position: "relative",
              }}
            >
            <picture>
              {THUMBNAIL_URL && (
                <source srcSet={THUMBNAIL_URL.replace('.jpg', '.webp')} type="image/webp" />
              )}
              <img
                src={THUMBNAIL_URL}
                alt={TITLE}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  color: "white",
                  fontFamily: "'Glacial Indifference', sans-serif"
                }}
              />
            </picture>
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              color="#fff"
              backgroundColor="#33333377"
              width={65}
              position={"absolute"}
              bottom={0}
              right={0}
              textAlign={"center"}
              fontSize={12}
              fontFamily={"'Glacial Indifference', sans-serif"}
            >
              {formatDuration(DURATION)}
            </Typography>
          </CardMedia>)}
        
        <CardContent sx={{ height: "50px" }}>
            <Typography variant="subtitle1" fontWeight="bold" color="#fff" fontFamily={"'Glacial Indifference', sans-serif"} component="div">
              <TruncatedText text={TITLE} maxLines={1} />
            </Typography>
            <Typography variant="subtitle2" fontSize={12} color="lightgrey" fontFamily={"'Glacial Indifference', sans-serif"} component="div">
              <TruncatedText text={DESCRIPTION} maxLines={2} />
            </Typography>
        </CardContent>
      </Card>
    </Link>
  );
};

export default VideoCard;
